




















































































































































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import metaStore from "@/store/modules/meta";
import { SpeedSection, RoadLink } from "@/store/models/meta";
import commonstore from "@/store/modules/common";

@Component({
  components: {}
})
export default class SectionCreateView extends Vue {
  isCreate: boolean = true;
  speedSection: SpeedSection = {} as SpeedSection;

  roadLinkList: RoadLink[] = [];

  async created() {
    const id = this.$route.query["id"];

    await metaStore.getRoadLink();
    this.roadLinkList = metaStore.roadLinkList;

    if (id) {
      this.isCreate = false;
      await metaStore.getSpeedSectionById(id);
      this.speedSection = metaStore.speedSection;
    }
  }

  get AdminRouter() {
    return AdminRouter;
  }

  async submit() {
    const id = this.$route.query["id"];
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    commonstore.showLoading();
    if(id) {
      await metaStore.patchSpeedSection(this.speedSection);
      this.$snotify.success("Speed Section Changed Successfully");
    }
    else{
      await metaStore.createSpeedSection(this.speedSection);
      this.$snotify.success("Speed Section Created Successfully");
    }
    commonstore.hideLoading();
    this.$router.push(AdminRouter.SpeedSection);
  }

  roadLinkRemoteMethod(query: any) {
    if (query !== '') {
      setTimeout(() => {
        this.roadLinkList = metaStore.roadLinkList.filter(item => {
          return item.roadLinkName.toLowerCase()
            .indexOf(query.toLowerCase()) > -1;
        });
      }, 200);
    } else {
      this.roadLinkList = metaStore.roadLinkList;
    }
  }

  async cancelForm() {
    this.$router.push(AdminRouter.SpeedSection);
  }
}
